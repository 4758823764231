<template>
    <v-fade-transition>
        <div class="col-12 pa-0">
            <v-sheet class="dense-inputs">
                <div class="d-flex flex-row align-end">
                    <div class="d-flex flex-column">
                        <span class="font-sm">{{ $t('message.warehouse') }}</span>
                        <v-select
                            :items="filteredOffices"
                            class="filter-width-75 bg-lightblue"
                            dense
                            hide-details="auto"
                            item-value="Office.code"
                            item-text="Office.code"
                            solo
                            v-model="filterValues.warehouse"
                            @change="fetchReport()"
                        />
                    </div>
                    <v-btn class="ml-3" @click="fetchReport()" :loading="loading.incomingStock">{{ $t('message.refetch') }}</v-btn>
                    <div class="d-flex flex-column ml-10">
                        <span class="font-sm">{{ exRateTitle[filterValues.warehouse] }}</span>
                        <div class="d-flex flex-row align-center">
                            <v-text-field
                                class="filter-width-100 bg-lightorange"
                                dense
                                hide-details="auto"
                                solo
                                type="number"
                                v-model="currentExchangeRates__MY"
                                v-if="filterValues.warehouse == 'MY'"
                            ></v-text-field>
                            <v-text-field
                                class="filter-width-125 bg-lightorange"
                                dense
                                hide-details="auto"
                                solo
                                type="number"
                                v-model="currentExchangeRates__ID"
                                v-if="filterValues.warehouse == 'ID'"
                            ></v-text-field>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon @click="getUpdatedExRate()" v-on="on" class="ml-1 orange--text text--darken-3">
                                        <div class="xe-icon">XR</div>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.getUpdatedExchangeRate') }}</span>
                            </v-tooltip>
                            <span class="font-italic">{{ '(last updated: ' + (currentExchangeRates__lastUpdate ? formatDate(currentExchangeRates__lastUpdate) : '' ) + ')'}}</span>
                        </div>
                    </div>
                    <v-spacer/>
                    <div class="d-flex flex-column ml-5">
                        <span class="font-sm">{{ $t('message.export') }}</span>
                        <div class="d-flex flex-row">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on: menu, attrs }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn
                                                color="default"
                                                v-bind="attrs"
                                                v-on="{ ...tooltip, ...menu }"
                                            >
                                                <v-icon class="far fa-file-pdf" color="red darken-1"></v-icon>
                                            </v-btn>
                                        </template>
                                        <span>PDF</span>
                                    </v-tooltip>
                                </template>
                                <v-list>
                                    <v-list-item class="text-no-wrap" @click="exportList('incoming','pdf')">{{ 'PDF ' + $t('message.availableList') }}</v-list-item>
                                    <template v-for="item in priceMargins">
                                        <v-list-item class="text-no-wrap" @click="exportList('priceList_' + item.PriceMargin.margin.toString(),'pdf','pm_' + item.PriceMargin.margin.toString())">{{ 'PDF ' + $t('message.priceList') + ' ' + item.PriceMargin.margin.toString() + '%' }}</v-list-item>
                                    </template>
                                </v-list>
                            </v-menu>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on: menu, attrs }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn
                                                color="default"
                                                class="ml-3"
                                                v-bind="attrs"
                                                v-on="{ ...tooltip, ...menu }"
                                            >
                                                <v-icon class="far fa-file-excel" color="green darken-2"></v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Excel</span>
                                    </v-tooltip>
                                </template>
                                <v-list>
                                    <v-list-item class="text-no-wrap" @click="exportList('incoming','excel')">{{ 'Excel ' + $t('message.availableList') }}</v-list-item>
                                    <template v-for="item in priceMargins">
                                        <v-list-item class="text-no-wrap" @click="exportList('priceList_' + item.PriceMargin.margin.toString(),'excel','pm_' + item.PriceMargin.margin.toString())">{{ 'Excel ' + $t('message.priceList') + ' ' + item.PriceMargin.margin.toString() + '%' }}</v-list-item>
                                    </template>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                </div>
            </v-sheet>
            <v-overlay
                :value="loading.incomingStock"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        />
                    </v-col>
                </v-row>
            </v-overlay>
            <v-data-table
                :fixed-header="true"
                :headers="headers"
                :height="tableHeight"
                :items="filteredItems"
                :items-per-page="-1"
                :sort-by="['Stock.eta']"
                :selectable-key="'Stock.id'"
                show-select
                class="mt-3 appic-table-light specification-table"
                dense
                hide-default-footer
                ref="incomingStockTable"
                item-key="Stock.id"
                v-model="selectedStock"
            >
                <template v-slot:header.Stock.po="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.po"
                            class="bg-white font-weight-light font-sm"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            @change="filterList('po',$event)"
                            v-if="header.searchable"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.etaDate="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.etaDate"
                            class="bg-white font-weight-light font-sm"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            @change="filterList('etaDate',$event)"
                            v-if="header.searchable"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.etaDateWh="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.etaDateWh"
                            class="bg-white font-weight-light font-sm"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            @change="filterList('etaDateWh',$event)"
                            v-if="header.searchable"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.containers="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.containers"
                            class="bg-white font-weight-light font-sm"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            @change="filterList('containers',$event)"
                            v-if="header.searchable"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.species="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.species"
                            class="bg-white font-weight-light font-sm"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            @change="filterList('species',$event)"
                            v-if="header.searchable"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.product="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.product"
                            class="bg-white font-weight-light font-sm"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            @change="filterList('product',$event)"
                            v-if="header.searchable"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.certification="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.certification"
                            class="bg-white font-weight-light font-sm"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            @change="filterList('certification',$event)"
                            v-if="header.searchable"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.supplier="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.supplier"
                            class="bg-white font-weight-light font-sm"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            @change="filterList('supplier',$event)"
                            v-if="header.searchable"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.grade="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.grade"
                            class="bg-white font-weight-light font-sm"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            @change="filterList('grade',$event)"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.thickness="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.thickness"
                            class="bg-white font-weight-light font-sm"
                            clearable
                            dense
                            hide-details="auto"
                            multiples
                            @change="filterList('thickness',$event)"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.width="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.width"
                            class="bg-white font-weight-light"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            @change="filterList('width',$event)"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.length="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.length"
                            class="bg-white font-weight-light"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            @change="filterList('length',$event)"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.volume="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.volume"
                            class="bg-white font-weight-light"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            @change="filterList('volume',$event)"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.buyingPrice="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.buyingPrice"
                            class="bg-white font-weight-light"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            @change="filterList('buyingPrice',$event)"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.currencyOriginal="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <div style="height: 30px"/>
                    </div>
                </template>
                <template v-slot:header.Stock.buyingPriceLocal="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <v-autocomplete
                            :items="filterOptions.buyingPriceLocal"
                            class="bg-white font-weight-light"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            @change="filterList('buyingPriceLocal',$event)"
                        />
                    </div>
                </template>
                <template v-slot:header.Stock.currencyLocal="{ header }">
                    <div class="d-flex flex-column">
                        <div>{{ header.text }}</div>
                        <div style="height: 30px"/>
                    </div>
                </template>
                <template v-slot:header.Stock.suggestedSellingPriceLocal="{ header }">
                    <div class="d-flex flex-column" style="width: 200px">
                        <div class="text-no-wrap text-center">{{ header.text }}</div>
                        <v-row no-gutters style="height: 30px !important;">
                            <template v-for="item in priceMargins">
                                <v-col class="text-end pt-2" :key="item.PriceMargin.id">{{ (item.PriceMargin.margin).toString() + '%' }}</v-col>
                            </template>
                        </v-row>
                    </div>
                </template>
                <template v-slot:item.Stock.etaDate="{ item }">
                    <div>{{ formatDate(item.Stock.etaDate) }}</div>
                </template>
                <template v-slot:item.Stock.volume="{ item }">
                    <div class="text-end">{{ item.Stock.volume ? item.Stock.volume.toString() + ' ' + item.Stock.uofmVolume : '' }}</div>
                </template>
                <template v-slot:item.Stock.buyingPrice="{ item }">
                    <div class="text-end">{{ numberFormat(item.Stock.buyingPrice, currencyFormat) }}</div>
                </template>
                <template v-slot:item.Stock.currencyOriginal="{ item }">
                    <span>{{ item.Stock.currencyOriginal }}</span>
                </template>
                <template v-slot:item.Stock.buyingPriceLocal="{ item }">
                    <div class="text-end">{{ numberFormat(item.Stock.buyingPriceLocal, currencyFormat) }}</div>
                </template>
                <template v-slot:item.Stock.currencyLocal="{ item }">
                    <span>{{ item.Stock.currencyLocal }}</span>
                </template>
                <template v-slot:item.Stock.suggestedSellingPriceLocal="{ item }">
                    <div class="d-flex flex-row" style="width: 200px">
                        <template v-for="priceMargin in priceMargins">
                            <div class="flex-grow-1 text-end font-weight-bold" :key="'sg_' + item.Stock.id.toString() + '_' + parseInt(priceMargin.PriceMargin.margin).toString()">{{ numberFormat(item.Stock.suggestedSellingPriceLocal['pm_' + parseInt(priceMargin.PriceMargin.margin).toString()], '0,0') }}</div>
                        </template>
                    </div>
                </template>
            </v-data-table>
        </div>
    </v-fade-transition>
</template>

<script>
import {api} from "Api";
import {mapGetters} from "vuex";
import {log, formatDate, numberFormat} from "Helpers/helpers";
import axios from "axios";
import {mapFields} from "vuex-map-fields";

const ExportTableJson = () => import("Components/Appic/ExportTableJson");

export default {
    name: "IncomingStockListV2",
    components: {ExportTableJson},
    data() {
        return {
            exRateTitle: {
                MY: "MYR -> 1 USD",
                ID: "IDR -> 1 USD",
            },
            filteredItems: [],
            filterOptions: {
                buyingPrice: [],
                buyingPriceLocal: [],
                certification: [],
                containers: [],
                etaDate: [],
                etaDateWh: [],
                grade: [],
                length: [],
                po: [],
                product: [],
                species: [],
                supplier: [],
                thickness: [],
                volume: [],
                width: []
            },
            filterValues: {
                warehouse: 'MY',
                buyingPrice: [],
                buyingPriceLocal: [],
                certification: [],
                containers: [],
                etaDate: [],
                etaDateWh: [],
                grade: [],
                length: [],
                po: [],
                product: [],
                quantity: [],
                species: [],
                supplier: [],
                thickness: [],
                volume: [],
                width: []
            },
            items: [],
            loading: {
                incomingStock: false
            },
            priceMargins: [],
            selectedStock: [],
            tableHeight: '500',
            totalBuyingCosts: {},
            totalBuyingCostsByProduct: {},
            totalQuantities: {},
            totalVolumes: {}
        }
    },
    computed: {
        ...mapGetters([
            'offices'
        ]),
        ...mapFields('runtime',{
            stockReportsCurrentWarehouse: 'stockReportsCurrentWarehouse'
        }),
        ...mapFields('stock', {
            currentExchangeRates__lastUpdate: 'currentExchangeRates.lastUpdate',
            currentExchangeRates__MY: 'currentExchangeRates.MY',
            currentExchangeRates__ID: 'currentExchangeRates.ID'
        }),
        currencyFormat() {
            let currencyFormat = '0,0.00'
            if(this.filterValues.warehouse == 'ID') currencyFormat = '0,0'
            return currencyFormat
        },
        filteredOffices () {
            return this.offices.filter( o => [1,21].includes(o.Office.id))
        },
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.po'),
                    value: 'Stock.po',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 7,
                    text: this.$t('message.supplier'),
                    value: 'Stock.supplier',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 3,
                    text: this.$t('message.eta'),
                    value: 'Stock.etaDate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 5,
                    text: this.$t('message.container'),
                    value: 'Stock.containers',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 6,
                    text: this.$t('message.product'),
                    value: 'Stock.product',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 7,
                    text: this.$t('message.species'),
                    value: 'Stock.species',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 8,
                    text: this.$t('message.claim'),
                    value: 'Stock.certification',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 9,
                    text: this.$t('message.grade'),
                    value: 'Stock.grade',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 10,
                    text: 'T',
                    value: 'Stock.thickness',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 11,
                    text: 'W',
                    value: 'Stock.width',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 12,
                    text: 'L',
                    value: 'Stock.length',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 13,
                    text: this.$t('message.quantity'),
                    value: 'Stock.volume',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 14,
                    text: this.$t('message.buyingPriceOriginal'),
                    value: 'Stock.buyingPrice',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 15,
                    text: this.$t('message.cur'),
                    value: 'Stock.currencyOriginal',
                    class: 'light-green lighten-3 pa-1 min-width-40',
                    cellClass: 'min-width-40',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 16,
                    text: this.$t('message.buyingPriceLocal'),
                    value: 'Stock.buyingPriceLocal',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 17,
                    text: this.$t('message.cur'),
                    value: 'Stock.currencyLocal',
                    class: 'light-green lighten-3 pa-1 min-width-40',
                    cellClass: 'min-width-40',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 18,
                    text: this.$t('message.suggestedSellingPriceLocal'),
                    value: 'Stock.suggestedSellingPriceLocal',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false,
                    searchable: false
                }
            ]
        }
    },
    methods: {
        calculateTotals() {
            return new Promise((resolve, reject) => {
                try {
                    this.totalBuyingCosts = {}
                    this.totalBuyingCostsByProduct = {}
                    this.totalQuantities = {}
                    this.totalVolumes = {}
                    this.items.forEach(item => {
                        if( !this.totalVolumes.hasOwnProperty(item.Stock.uofmVolume) ) this.totalVolumes[item.Stock.uofmVolume] = 0
                        if( !this.totalBuyingCosts.hasOwnProperty(item.Stock.uofmVolume) ) this.totalBuyingCosts[item.Stock.uofmVolume] = 0
                        this.totalVolumes[item.Stock.uofmVolume] += parseFloat(item.Stock.volume)
                        this.totalBuyingCosts[item.Stock.uofmVolume] += parseFloat(item.Stock.buyingCost)

                        if( !this.totalBuyingCostsByProduct.hasOwnProperty(item.Stock.product) ) this.totalBuyingCostsByProduct[item.Stock.product] = 0
                        this.totalBuyingCostsByProduct[item.Stock.product] += parseFloat(item.Stock.buyingCost)
                    })
                    this.totalBuyingCostsByProduct.sort((a, b) => {
                        if(a > b) return -1
                        if(a < b) return 1
                        return 0
                    })
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        clearFilterOptions() {
            return new Promise((resolve, reject) => {
                try {
                    this.headers.forEach(header => {
                        if (header.searchable) {
                            const option = header.value
                            const [a, b] = option.split('.')
                            this.filterOptions[b] = []
                        }
                    })
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        async exportList(report, reportType, priceMargin = null) {
            const date = new Date()
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const fullDate = year + "-" + month + "-" + day

            let reportFileName = 'Incoming_Stock_List_' + fullDate
            let url = '/reports/stocks/incoming/export'
            let headers = [
                this.$t('message.po'),
                this.$t('message.supplier'),
                this.$t('message.eta'),
                this.$t('message.container'),
                this.$t('message.product'),
                this.$t('message.species'),
                this.$t('message.claim'),
                this.$t('message.grade'),
                this.$t('message.thickness'),
                this.$t('message.width'),
                this.$t('message.length'),
                this.$t('message.quantity'),
                this.$t('message.unit'),
                this.$t('message.buyingPriceOriginal'),
                this.$t('message.cur'),
                this.$t('message.buyingPriceLocal'),
                this.$t('message.cur')
            ]
            this.priceMargins.forEach(pm => {
                headers.push(this.$t('message.sellingPrice') + ' ' + pm.PriceMargin.margin.toString() + '%')
            })
            if(report != 'incoming') {
                reportFileName = 'Price_List_' + fullDate
                url = '/reports/stocks/incoming/price-list/export'
                headers = [
                    this.$t('message.po'),
                    this.$t('message.supplier'),
                    this.$t('message.eta'),
                    this.$t('message.container'),
                    this.$t('message.product'),
                    this.$t('message.species'),
                    this.$t('message.claim'),
                    this.$t('message.grade'),
                    this.$t('message.thickness'),
                    this.$t('message.width'),
                    this.$t('message.length'),
                    this.$t('message.quantity'),
                    this.$t('message.unit'),
                    this.$t('message.buyingPriceOriginal'),
                    this.$t('message.cur'),
                    this.$t('message.buyingPriceLocal'),
                    this.$t('message.cur'),
                    this.$t('message.sellingPrice')
                ]
            }
            let reportFileExtension = '.pdf'
            let reportFileType = 'application/pdf'
            if(reportType == 'excel'){
                reportFileExtension = '.xlsx'
                reportFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
            try {
                this.$toast(this.$t('message.successes.exportedListReadyMoment'), {
                    classes: ['text--white'],
                    color: 'info',
                    timeout: 0,
                    icon: 'check',
                    iconColor: 'white',
                    x: 'center',
                    y: 'top'
                })
                let data = []
                const selectedItemsForExport = this.selectedStock.length > 0 ? this.selectedStock : this.filteredItems
                selectedItemsForExport.forEach(item => {
                    if(report == 'incoming'){
                        const itemObj = {
                            po: item.Stock.po,
                            supplier: item.Stock.supplier,
                            eta: item.Stock.etaDate,
                            containers: item.Stock.containers,
                            product: item.Stock.product,
                            species: item.Stock.species,
                            claim: item.Stock.certification,
                            grade: item.Stock.grade,
                            thickness: item.Stock.thickness,
                            width: item.Stock.width,
                            length: item.Stock.length,
                            volume: item.Stock.volume,
                            uofmVolume: item.Stock.uofmVolume,
                            buyingPrice: item.Stock.buyingPrice,
                            currencyOriginal: item.Stock.currencyOriginal,
                            buyingPriceLocal: item.Stock.buyingPriceLocal,
                            currencyLocal: item.Stock.currencyLocal
                        }
                        this.priceMargins.forEach(pm => {
                            itemObj['sellingPrice' + parseInt(pm.PriceMargin.margin).toString()] = item.Stock.suggestedSellingPriceLocal['pm_' + parseInt(pm.PriceMargin.margin).toString()]
                        })
                        data.push(itemObj)
                    } else {
                        const sellingPrice =  item.Stock.suggestedSellingPriceLocal[priceMargin]
                        const itemObj = {
                            po: item.Stock.po,
                            supplier: item.Stock.supplier,
                            eta: item.Stock.etaDate,
                            containers: item.Stock.containers,
                            product: item.Stock.product,
                            species: item.Stock.species,
                            claim: item.Stock.certification,
                            grade: item.Stock.grade,
                            thickness: item.Stock.thickness,
                            width: item.Stock.width,
                            length: item.Stock.length,
                            volume: item.Stock.volume,
                            uofmVolume: item.Stock.uofmVolume,
                            buyingPrice: item.Stock.buyingPrice,
                            currencyOriginal: item.Stock.currencyOriginal,
                            buyingPriceLocal: item.Stock.buyingPriceLocal,
                            currencyLocal: item.Stock.currencyLocal,
                            sellingPrice: sellingPrice
                        }
                        data.push(itemObj)
                    }
                })

                let exportedFile = await api.post(url, {
                    data: data,
                    fields: headers,
                    type: reportType,
                    warehouse: this.filterValues.warehouse
                }, {
                    responseType: 'blob'
                })
                const fileUrl = window.URL.createObjectURL(new Blob([exportedFile.data], {type: reportFileType}));
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank'
                link.setAttribute('download', reportFileName + reportFileExtension);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(fileUrl);
                //close notification
                let cmp = this.$toast.getCmp()
                cmp.close()
            } catch(error){
                this.$toast.error(this.$t('message.errors.couldNotExportList') + ": " + error, {
                    timeout: 7000,
                    x: 'center',
                    y: 'top'
                })
            }
        },
        fetch() {
            return new Promise((resolve, reject) => {
                try {
                    this.loadIncomingStock()
                        .then(() => {
                            this.loadFilterOptions()
                                .then(() => {
                                    resolve('done')
                                })
                                .catch((e) => {
                                    reject(e)
                                })
                        })
                        .catch((e) => {
                            reject(e)
                        })
                } catch(e) {
                    reject(e)
                }
            })
        },
        fetchReport() {
            this.loading.incomingStock = true
            this.clearFilterOptions()
                .then(() => {
                    this.fetch()
                        .then(() => {
                            this.filterList()
                                .then(() => {
                                    this.loading.incomingStock = false
                                    this.calculateTotals()
                                        .then(() => {
                                            this.loading.incomingStock = false
                                        })
                                        .catch(() => {
                                            this.loading.incomingStock = false
                                        })
                                })
                                .catch(() => {
                                    this.loading.incomingStock = false
                                })
                        })
                        .catch(() => {
                            this.loading.incomingStock = false
                        })
                })
                .catch(() => {
                    this.loading.incomingStock = false
                })
        },
        filterList(column = null, value = []) {
            return new Promise((resolve, reject) => {
                try {
                    this.filterValues[column] = value
                    let filterFunctions = []
                    for(const field in this.filterValues){
                        if(field != 'warehouse') {
                            if (this.filterValues[field] != null && this.filterValues[field].length > 0) {
                                filterFunctions.push((item) => {
                                    return this.filterValues[field].includes(item['Stock'][field])
                                })
                            }
                        }
                    }
                    if(filterFunctions.length > 0){
                        this.filteredItems = this.items.filter(item => filterFunctions.every(f => f(item)))
                    } else {
                        this.filteredItems = this.items
                    }
                    resolve('done')
                } catch (e) {
                    reject(e)
                }
            })
        },
        formatDate,
        getPriceMargins() {
            return new Promise((resolve, reject) => {
                api
                    .get('/warehouse-vars/price-margin')
                    .then((response) => {
                        const priceMargins = response.data.data
                        resolve(priceMargins)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        async getUpdatedExRate() {
            let warehouseCurrency = 'MYR'
            if(this.filterValues.warehouse == 'ID') warehouseCurrency = 'IDR'
            const apiEndPoint = process.env.VUE_APP_EXRATE_URL + '/latest?base=USD&symbols=' + warehouseCurrency
            if(await this.$root.$confirm(this.$t('message.updateExchangeRate') + ' ' + warehouseCurrency + ':USD', this.$t('message.confirmations.continueGetExchangeRateAction'), {color: 'orange'})){
                const apiNoAuth = axios.create()
                const config = {
                    method: "get",
                    url: apiEndPoint,
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
                apiNoAuth(config)
                    .then(response => {
                        if(this.filterValues.warehouse == 'MY') this.currentExchangeRates__MY = response.data.rates[warehouseCurrency]
                        if(this.filterValues.warehouse == 'ID') this.currentExchangeRates__ID = response.data.rates[warehouseCurrency]
                        this.currentExchangeRates__lastUpdate = response.data.date
                        this.updateStockExRate(response.data)
                    })
                    .catch(e => {
                        this.$toast.error(e,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        getStockExRate() {
            return new Promise((resolve, reject) => {
                let toCurrency = 'MYR'
                if(this.filterValues.warehouse == 'ID') toCurrency = 'IDR'
                const conditions = [
                    {field: 'from_currency', value: 'USD'},
                    {field: 'to_currency', value: toCurrency},
                ]
                api
                    .get("/reports/stocks/user-exchange-rate", {
                        params: {
                            conditions: conditions
                        }
                    })
                    .then(response => {
                        if(response.data.status == 'success') {
                            if(this.filterValues.warehouse == 'MY') this.currentExchangeRates__MY = response.data.data[0]['exrate']
                            if(this.filterValues.warehouse == 'ID') this.currentExchangeRates__ID = response.data.data[0]['exrate']
                            this.currentExchangeRates__lastUpdate = response.data.data[0]['rate_date']
                            resolve('done')
                        } else {
                            resolve('error')
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        handleResize() {
            // const scrollbarVisible = (element) => {
            //     return element.scrollHeight > element.clientHeight;
            // }
            // const tableFooterContainer = document.querySelector('#incomingMainTableFooterContainer')
            // if(scrollbarVisible(tableFooterContainer)){
            //     this.tableHeight = window.innerHeight - (360);
            // } else {
            //     this.tableHeight = window.innerHeight - (340);
            // }
            this.tableHeight = window.innerHeight - 225;
            return true
        },
        loadFilterOptions() {
            return new Promise((resolve, reject) => {
                try {
                    this.headers.forEach(header => {
                        if (header.searchable) {
                            const option = header.value
                            const [a, b] = option.split('.')
                            let options = this.items.map(item => item[a][b])
                            if (options.length > 0) {
                                options.filter((item, index) => options.indexOf(item) === index)
                                options.filter(Boolean)
                                options.sort()
                                this.filterOptions[b] = options
                            }
                        }
                    })
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        loadIncomingStock() {
            return new Promise((resolve, reject) => {
                const conditions = [
                    {
                        field: 'contracts.destinatione_id',
                        value: this.filterValues.warehouse
                    }
                ]
                const exRate = {
                    source: "USD",
                    target: this.filterValues.warehouse == 'MY' ? "MYR" : "IDR",
                    rate: this.filterValues.warehouse == 'MY' ? parseFloat(this.currentExchangeRates__MY) : parseFloat(this.currentExchangeRates__ID)
                }
                api
                    .get("/reports/stocks/incoming",{
                        params: {
                            conditions: conditions,
                            exrate: exRate
                        }
                    })
                    .then(response => {
                        this.items = response.data.data
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        numberFormat,
        updateStockExRate(rates) {
            return new Promise((resolve, reject) => {
                api
                    .post("/reports/stocks/user-exchange-rate", {
                        rates: rates
                    })
                    .then(response => {
                        if(response.data.status == 'success') {
                            resolve('done')
                        } else {
                            resolve('error')
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        }
    },
    watch: {
        'filterValues.warehouse'(newVal) {
            this.stockReportsCurrentWarehouse = newVal
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
        this.getStockExRate()
        this.getPriceMargins()
            .then((priceMargins) => {
                this.priceMargins = priceMargins
                this.fetchReport()
            })
            .catch((error) => {
                this.$toast.error(error,
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            })
        // this.$nextTick(() => {
        //     let isSyncingScroll = false
        //     let isSyncingFooterScroll = false
        //     const mainTable = document.querySelector('.v-data-table__wrapper')
        //     const tableFooter = document.querySelector('#incomingMainTableFooter')
        //     const tableFooterContainer = document.querySelector('#incomingMainTableFooterContainer')
        //
        //     //detect scrolling
        //     mainTable.addEventListener('scroll',function(){
        //         if (!isSyncingScroll) {
        //             isSyncingScroll = true
        //             tableFooterContainer.scrollLeft = this.scrollLeft
        //         }
        //         isSyncingScroll = false
        //     })
        //     //detect scrolling
        //     tableFooterContainer.addEventListener('scroll', function(){
        //         if (!isSyncingFooterScroll) {
        //             isSyncingFooterScroll = true
        //             mainTable.scrollLeft = this.scrollLeft
        //         }
        //         isSyncingFooterScroll = false
        //     })
        //
        //     let ro = new ResizeObserver(entries => {
        //         const headers = mainTable.querySelectorAll('th')
        //         const footerDivs = tableFooter.querySelectorAll('div.footer-cell')
        //         headers.forEach((header, index) => {
        //             footerDivs[index].style.width = header.offsetWidth.toString() + 'px'
        //         })
        //     })
        //     ro.observe(mainTable);
        // })
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.filter-width-50 {
    width: 50px !important;
    max-width: 50px !important;
}
.filter-width-60 {
    width: 60px !important;
    max-width: 60px !important;
}
.filter-width-70 {
    width: 70px !important;
    max-width: 70px !important;
}
.filter-width-75 {
    width: 75px !important;
    max-width: 75px !important;
}
.filter-width-100 {
    width: 100px !important;
    max-width: 100px !important;
}
.filter-width-150 {
    width: 150px !important;
    max-width: 150px !important;
}
.bg-white {
    background-color: white;
}
.bg-lightblue {
    background-color: lightblue;
}
.bg-lightorange {
    background-color: #FFCC80;
}
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
    min-width: 100px !important;
}
.v-data-table::v-deep .v-data-table-header__icon {
    position: absolute;
    right: 2px;
    top: 4px;
}
.v-data-table::v-deep .min-width-40 {
    min-width: 40px !important;
}
table::v-deep tbody > tr > td:nth-child(2),
table::v-deep thead > tr > th:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 9998;
    background: white;
}
table::v-deep thead > tr > th:nth-child(2) {
    z-index: 9999;
}
.table-footer {
    height: 120px;
}
.table-footer-border {
    border-left: lightgray 1px solid;
    border-bottom: lightgray 1px solid;
    border-right: lightgray 1px solid;
}
.v-data-table::v-deep .v-data-table__wrapper {
    overflow-x: hidden;
}
.v-data-table::v-deep  thead > tr > th:nth-child(1) {
    min-width: unset !important;
    background-color: #c5e1a5;
    width: 1px !important;
}
.v-data-table::v-deep  tbody > tr > td:nth-child(1) {
    min-width: unset !important;
    width: 1px !important;
}
</style>