var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',[_c('div',{staticClass:"col-12 pa-0"},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('div',{staticClass:"d-flex flex-row align-end"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-sm"},[_vm._v(_vm._s(_vm.$t('message.warehouse')))]),_c('v-select',{staticClass:"filter-width-75 bg-lightblue",attrs:{"items":_vm.filteredOffices,"dense":"","hide-details":"auto","item-value":"Office.code","item-text":"Office.code","solo":""},on:{"change":function($event){return _vm.fetchReport()}},model:{value:(_vm.filterValues.warehouse),callback:function ($$v) {_vm.$set(_vm.filterValues, "warehouse", $$v)},expression:"filterValues.warehouse"}})],1),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.incomingStock},on:{"click":function($event){return _vm.fetchReport()}}},[_vm._v(_vm._s(_vm.$t('message.refetch')))]),_c('div',{staticClass:"d-flex flex-column ml-10"},[_c('span',{staticClass:"font-sm"},[_vm._v(_vm._s(_vm.exRateTitle[_vm.filterValues.warehouse]))]),_c('div',{staticClass:"d-flex flex-row align-center"},[(_vm.filterValues.warehouse == 'MY')?_c('v-text-field',{staticClass:"filter-width-100 bg-lightorange",attrs:{"dense":"","hide-details":"auto","solo":"","type":"number"},model:{value:(_vm.currentExchangeRates__MY),callback:function ($$v) {_vm.currentExchangeRates__MY=$$v},expression:"currentExchangeRates__MY"}}):_vm._e(),(_vm.filterValues.warehouse == 'ID')?_c('v-text-field',{staticClass:"filter-width-125 bg-lightorange",attrs:{"dense":"","hide-details":"auto","solo":"","type":"number"},model:{value:(_vm.currentExchangeRates__ID),callback:function ($$v) {_vm.currentExchangeRates__ID=$$v},expression:"currentExchangeRates__ID"}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1 orange--text text--darken-3",attrs:{"icon":""},on:{"click":function($event){return _vm.getUpdatedExRate()}}},on),[_c('div',{staticClass:"xe-icon"},[_vm._v("XR")])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('message.getUpdatedExchangeRate')))])]),_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s('(last updated: ' + (_vm.currentExchangeRates__lastUpdate ? _vm.formatDate(_vm.currentExchangeRates__lastUpdate) : '' ) + ')'))])],1)]),_c('v-spacer'),_c('div',{staticClass:"d-flex flex-column ml-5"},[_c('span',{staticClass:"font-sm"},[_vm._v(_vm._s(_vm.$t('message.export')))]),_c('div',{staticClass:"d-flex flex-row"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"default"}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{staticClass:"far fa-file-pdf",attrs:{"color":"red darken-1"}})],1)]}}],null,true)},[_c('span',[_vm._v("PDF")])])]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"text-no-wrap",on:{"click":function($event){return _vm.exportList('incoming','pdf')}}},[_vm._v(_vm._s('PDF ' + _vm.$t('message.availableList')))]),_vm._l((_vm.priceMargins),function(item){return [_c('v-list-item',{staticClass:"text-no-wrap",on:{"click":function($event){_vm.exportList('priceList_' + item.PriceMargin.margin.toString(),'pdf','pm_' + item.PriceMargin.margin.toString())}}},[_vm._v(_vm._s('PDF ' + _vm.$t('message.priceList') + ' ' + item.PriceMargin.margin.toString() + '%'))])]})],2)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"default"}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{staticClass:"far fa-file-excel",attrs:{"color":"green darken-2"}})],1)]}}],null,true)},[_c('span',[_vm._v("Excel")])])]}}])},[_c('v-list',[_c('v-list-item',{staticClass:"text-no-wrap",on:{"click":function($event){return _vm.exportList('incoming','excel')}}},[_vm._v(_vm._s('Excel ' + _vm.$t('message.availableList')))]),_vm._l((_vm.priceMargins),function(item){return [_c('v-list-item',{staticClass:"text-no-wrap",on:{"click":function($event){_vm.exportList('priceList_' + item.PriceMargin.margin.toString(),'excel','pm_' + item.PriceMargin.margin.toString())}}},[_vm._v(_vm._s('Excel ' + _vm.$t('message.priceList') + ' ' + item.PriceMargin.margin.toString() + '%'))])]})],2)],1)],1)])],1)]),_c('v-overlay',{attrs:{"value":_vm.loading.incomingStock,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{ref:"incomingStockTable",staticClass:"mt-3 appic-table-light specification-table",attrs:{"fixed-header":true,"headers":_vm.headers,"height":_vm.tableHeight,"items":_vm.filteredItems,"items-per-page":-1,"sort-by":['Stock.eta'],"selectable-key":'Stock.id',"show-select":"","dense":"","hide-default-footer":"","item-key":"Stock.id"},scopedSlots:_vm._u([{key:"header.Stock.po",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),(header.searchable)?_c('v-autocomplete',{staticClass:"bg-white font-weight-light font-sm",attrs:{"items":_vm.filterOptions.po,"clearable":"","dense":"","hide-details":"auto","multiple":""},on:{"change":function($event){return _vm.filterList('po',$event)}}}):_vm._e()],1)]}},{key:"header.Stock.etaDate",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),(header.searchable)?_c('v-autocomplete',{staticClass:"bg-white font-weight-light font-sm",attrs:{"items":_vm.filterOptions.etaDate,"clearable":"","dense":"","hide-details":"auto","multiple":""},on:{"change":function($event){return _vm.filterList('etaDate',$event)}}}):_vm._e()],1)]}},{key:"header.Stock.etaDateWh",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),(header.searchable)?_c('v-autocomplete',{staticClass:"bg-white font-weight-light font-sm",attrs:{"items":_vm.filterOptions.etaDateWh,"clearable":"","dense":"","hide-details":"auto","multiple":""},on:{"change":function($event){return _vm.filterList('etaDateWh',$event)}}}):_vm._e()],1)]}},{key:"header.Stock.containers",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),(header.searchable)?_c('v-autocomplete',{staticClass:"bg-white font-weight-light font-sm",attrs:{"items":_vm.filterOptions.containers,"clearable":"","dense":"","hide-details":"auto","multiple":""},on:{"change":function($event){return _vm.filterList('containers',$event)}}}):_vm._e()],1)]}},{key:"header.Stock.species",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),(header.searchable)?_c('v-autocomplete',{staticClass:"bg-white font-weight-light font-sm",attrs:{"items":_vm.filterOptions.species,"clearable":"","dense":"","hide-details":"auto","multiple":""},on:{"change":function($event){return _vm.filterList('species',$event)}}}):_vm._e()],1)]}},{key:"header.Stock.product",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),(header.searchable)?_c('v-autocomplete',{staticClass:"bg-white font-weight-light font-sm",attrs:{"items":_vm.filterOptions.product,"clearable":"","dense":"","hide-details":"auto","multiple":""},on:{"change":function($event){return _vm.filterList('product',$event)}}}):_vm._e()],1)]}},{key:"header.Stock.certification",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),(header.searchable)?_c('v-autocomplete',{staticClass:"bg-white font-weight-light font-sm",attrs:{"items":_vm.filterOptions.certification,"clearable":"","dense":"","hide-details":"auto","multiple":""},on:{"change":function($event){return _vm.filterList('certification',$event)}}}):_vm._e()],1)]}},{key:"header.Stock.supplier",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),(header.searchable)?_c('v-autocomplete',{staticClass:"bg-white font-weight-light font-sm",attrs:{"items":_vm.filterOptions.supplier,"clearable":"","dense":"","hide-details":"auto","multiple":""},on:{"change":function($event){return _vm.filterList('supplier',$event)}}}):_vm._e()],1)]}},{key:"header.Stock.grade",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('v-autocomplete',{staticClass:"bg-white font-weight-light font-sm",attrs:{"items":_vm.filterOptions.grade,"clearable":"","dense":"","hide-details":"auto","multiple":""},on:{"change":function($event){return _vm.filterList('grade',$event)}}})],1)]}},{key:"header.Stock.thickness",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('v-autocomplete',{staticClass:"bg-white font-weight-light font-sm",attrs:{"items":_vm.filterOptions.thickness,"clearable":"","dense":"","hide-details":"auto","multiples":""},on:{"change":function($event){return _vm.filterList('thickness',$event)}}})],1)]}},{key:"header.Stock.width",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('v-autocomplete',{staticClass:"bg-white font-weight-light",attrs:{"items":_vm.filterOptions.width,"clearable":"","dense":"","hide-details":"auto","multiple":""},on:{"change":function($event){return _vm.filterList('width',$event)}}})],1)]}},{key:"header.Stock.length",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('v-autocomplete',{staticClass:"bg-white font-weight-light",attrs:{"items":_vm.filterOptions.length,"clearable":"","dense":"","hide-details":"auto","multiple":""},on:{"change":function($event){return _vm.filterList('length',$event)}}})],1)]}},{key:"header.Stock.volume",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('v-autocomplete',{staticClass:"bg-white font-weight-light",attrs:{"items":_vm.filterOptions.volume,"clearable":"","dense":"","hide-details":"auto","multiple":""},on:{"change":function($event){return _vm.filterList('volume',$event)}}})],1)]}},{key:"header.Stock.buyingPrice",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('v-autocomplete',{staticClass:"bg-white font-weight-light",attrs:{"items":_vm.filterOptions.buyingPrice,"clearable":"","dense":"","hide-details":"auto","multiple":""},on:{"change":function($event){return _vm.filterList('buyingPrice',$event)}}})],1)]}},{key:"header.Stock.currencyOriginal",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('div',{staticStyle:{"height":"30px"}})])]}},{key:"header.Stock.buyingPriceLocal",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('v-autocomplete',{staticClass:"bg-white font-weight-light",attrs:{"items":_vm.filterOptions.buyingPriceLocal,"clearable":"","dense":"","hide-details":"auto","multiple":""},on:{"change":function($event){return _vm.filterList('buyingPriceLocal',$event)}}})],1)]}},{key:"header.Stock.currencyLocal",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v(_vm._s(header.text))]),_c('div',{staticStyle:{"height":"30px"}})])]}},{key:"header.Stock.suggestedSellingPriceLocal",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"width":"200px"}},[_c('div',{staticClass:"text-no-wrap text-center"},[_vm._v(_vm._s(header.text))]),_c('v-row',{staticStyle:{"height":"30px !important"},attrs:{"no-gutters":""}},[_vm._l((_vm.priceMargins),function(item){return [_c('v-col',{key:item.PriceMargin.id,staticClass:"text-end pt-2"},[_vm._v(_vm._s((item.PriceMargin.margin).toString() + '%'))])]})],2)],1)]}},{key:"item.Stock.etaDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.Stock.etaDate)))])]}},{key:"item.Stock.volume",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(item.Stock.volume ? item.Stock.volume.toString() + ' ' + item.Stock.uofmVolume : ''))])]}},{key:"item.Stock.buyingPrice",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.numberFormat(item.Stock.buyingPrice, _vm.currencyFormat)))])]}},{key:"item.Stock.currencyOriginal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Stock.currencyOriginal))])]}},{key:"item.Stock.buyingPriceLocal",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.numberFormat(item.Stock.buyingPriceLocal, _vm.currencyFormat)))])]}},{key:"item.Stock.currencyLocal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.Stock.currencyLocal))])]}},{key:"item.Stock.suggestedSellingPriceLocal",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row",staticStyle:{"width":"200px"}},[_vm._l((_vm.priceMargins),function(priceMargin){return [_c('div',{key:'sg_' + item.Stock.id.toString() + '_' + parseInt(priceMargin.PriceMargin.margin).toString(),staticClass:"flex-grow-1 text-end font-weight-bold"},[_vm._v(_vm._s(_vm.numberFormat(item.Stock.suggestedSellingPriceLocal['pm_' + parseInt(priceMargin.PriceMargin.margin).toString()], '0,0')))])]})],2)]}}]),model:{value:(_vm.selectedStock),callback:function ($$v) {_vm.selectedStock=$$v},expression:"selectedStock"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }